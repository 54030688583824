import ModelView from "../../views/ModelView";
import {AUTH_HELPER} from "../../utils/helpers";

export default [
    {
        path: '/tools',
        name: 'tools',
        redirect: '/tools/',
        meta: {
            requirePermission: true,
            model: AUTH_HELPER.authModels.Tool
        },
        component: ModelView,
        children: [
            {
                path: '',
                name: 'tools',
                meta: {
                    action: AUTH_HELPER.authActions.browse,
                    breadcrumb: [
                        {
                            label: 'Tools',
                        }
                    ]
                },
                component: () => import('../../views/Tools/Index')
            },
            {
                path: "/tools/new",
                name: "newTool",
                meta: {
                    action: AUTH_HELPER.authActions.add,
                    breadcrumb: [
                        {
                            parent: 'Tools',
                            label: 'New',
                        }
                    ]
                },
                component: () => import('../../views/Tools/ToolNew'),
            },
            {
                path: '/tools/:toolId',
                name: 'tool',
                meta: {
                    action: AUTH_HELPER.authActions.read,
                    breadcrumb: [
                        {
                            parent: 'Tools'
                        }
                    ]
                },
                component: () => import('../../views/Tools/Tool')
            },
            {
                path: "/tools/:toolId/edit",
                name: "editTool",
                component: () => import('../../views/Tools/ToolEdit'),
                meta: {
                    action: AUTH_HELPER.authActions.edit,
                    breadcrumb: [
                        {
                            parent: 'Tools',
                            label: 'Edit',
                        }
                    ]
                },
            },
        ]
    }
];