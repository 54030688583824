import * as API from "@/services/API";

const baseUrl = '/v1/feedback/';

export default {
    // browseRecords(payload, filters) {
    //     var endpoint = baseUrl;
    //     if (payload !== undefined) {
    //         endpoint += '?';
    //         var params = new URLSearchParams(payload);
    //         endpoint += params.toString();
    //     }
    //     return API.apiClient.get(endpoint, {params: filters});
    // },
    browseRecords(filters, tableFilters) {
        return API.apiClient.get(baseUrl, {params: filters, tableFilters});
    },
    readRecord(recordId) {
        return API.apiClient.get(baseUrl + recordId);
    },
    editRecord(payload) {
        return API.apiClient.patch(baseUrl + payload.id, payload);
    },
    addRecord(payload) {
        return API.apiClient.post(baseUrl, payload);
    },
    deleteRecord(id) {
        return API.apiClient.delete(baseUrl + id);
    },
    paginateRecords(link) {
        return API.apiClient.get(link);
    },

}