import {getError} from "../../utils/helpers";
import InstanceService from "../../services/InstanceService";

export const namespaced = true;

function setPaginatedRecords(commit, response) {
    commit("SET_RECORDS", response.data.data);
    commit("SET_META", response.data.meta);
    commit("SET_LINKS", response.data.links);
    commit("SET_LOADING", false);
}

export const state = () => ({
    records: [],
    record: null,
    meta: null,
    links: null,
    loading: false,
    error: null,

    browseRecordStatus: 0,
    readRecordStatus: 0,
    editRecordStatus: 0,
    addRecordStatus: 0,
    deleteRecordStatus: 0,

    paginateRecordStatus: 0,

    availablePhoneNumberCountries: [],
    availablePhoneNumberCountriesStatus: 0,
    availablePhoneNumbers: [],
    availablePhoneNumberStatus: 0,
    purchasePhoneNumberStatus: 0,
    purchasedPhoneNumber: null,
});

export const mutations = {
    SET_RECORDS(state, records) {
        state.records = records;
    },

    SET_RECORD(state, record) {
        state.record = record;
    },

    SET_META(state, meta) {
        state.meta = meta;
    },

    SET_LINKS(state, links) {
        state.links = links;
    },

    SET_LOADING(state, loading) {
        state.loading = loading;
    },

    SET_ERROR(state, error) {
        state.error = error;
    },

    SET_ADD_RECORD_STATUS(state, status) {
        state.addRecordStatus = status;
    },

    SET_EDIT_RECORD_STATUS(state, status) {
        state.editRecordStatus = status;
    },

    SET_DELETE_RECORD_STATUS(state, status) {
        state.deleteRecordStatus = status;
    },

    SET_BROWSE_RECORD_STATUS(state, status) {
        state.browseRecordStatus = status;
    },

    SET_READ_RECORD_STATUS(state, status) {
        state.readRecordStatus = status;
    },

    SET_PAGINATE_RECORD_STATUS(state, status) {
        state.paginateRecordStatus = status;
    },

    SET_AVAILABLE_PHONE_NUMBER_COUNTRIES(state, countries) {
        state.availablePhoneNumberCountries = countries;
    },

    SET_AVAILABLE_PHONE_NUMBER_COUNTRIES_STATUS(state, status) {
        state.availablePhoneNumberCountriesStatus = status;
    },

    SET_AVAILABLE_PHONE_NUMBERS(state, numbers) {
        state.availablePhoneNumbers = numbers;
    },

    SET_AVAILABLE_PHONE_NUMBER_STATUS(state, status) {
        state.availablePhoneNumberStatus = status;
    },

    SET_PURCHASE_PHONE_NUMBER_STATUS(state, status) {
        state.purchasePhoneNumberStatus = status;
    },

    SET_PURCHASED_PHONE_NUMBER(state, number) {
        state.purchasedPhoneNumber = number;
    }
};

export const getters = {
    records: (state) => {
        return state.records;
    },

    record: (state) => {
        return state.record;
    },

    meta: (state) => {
        return state.meta;
    },

    links: (state) => {
        return state.links;
    },

    loading: (state) => {
        return state.loading;
    },

    error: (state) => {
        return state.error;
    },

    browseRecordStatus: (state) => {
        return state.browseRecordStatus;
    },

    readRecordStatus: (state) => {
        return state.readRecordStatus;
    },

    editRecordStatus: (state) => {
        return state.editRecordStatus;
    },

    addRecordStatus: (state) => {
        return state.addRecordStatus;
    },

    deleteRecordStatus: (state) => {
        return state.deleteRecordStatus;
    },

    paginateRecordStatus: (state) => {
        return state.paginateRecordStatus;
    },

    availablePhoneNumberCountries: (state) => {
        return state.availablePhoneNumberCountries;
    },

    availablePhoneNumberCountriesStatus: (state) => {
        return state.availablePhoneNumberCountriesStatus;
    },

    availablePhoneNumbers: (state) => {
        return state.availablePhoneNumbers;
    },

    availablePhoneNumberStatus: (state) => {
        return state.availablePhoneNumberStatus;
    },

    purchasePhoneNumberStatus: (state) => {
        return state.purchasePhoneNumberStatus;
    },

    purchasedPhoneNumber: (state) => {
        return state.purchasedPhoneNumber;
    }
};

export const actions = {
    browseRecords({commit}, payload) {
        commit("SET_ERROR", null);
        commit("SET_LOADING", true);
        commit("SET_BROWSE_RECORD_STATUS", 1);
        InstanceService.browseRecords(payload).then((response) => {
            setPaginatedRecords(commit, response);
            commit("SET_BROWSE_RECORD_STATUS", 2);
        }).catch((error) => {
            commit("SET_LOADING", false);
            commit("SET_BROWSE_RECORD_STATUS", 3);
            commit("SET_ERROR", getError(error));
        });
    },
    readRecord({commit}, recordId) {
        commit("SET_ERROR", null);
        commit("SET_READ_RECORD_STATUS", 1);
        InstanceService.readRecord(recordId).then((response) => {
            console.log("INSTANCE READ NO ERROR");
            commit("SET_READ_RECORD_STATUS", 2);
            commit("SET_RECORD", response.data.data);
        }).catch((error) => {
            console.log("INSTANCE READ ERROR");
            commit("SET_READ_RECORD_STATUS", 3);
            commit("SET_RECORD", null);
            commit("SET_ERROR", getError(error));
        });
    },
    editRecord({commit}, payload) {
        commit("SET_ERROR", null);
        commit('SET_EDIT_RECORD_STATUS', 1);
        InstanceService.editRecord(payload)
            .then((response) => {
                commit('SET_EDIT_RECORD_STATUS', 2);
                commit('SET_RECORD', response.data.data);
            })
            .catch((error) => {
                commit('SET_EDIT_RECORD_STATUS', 3);
                commit('SET_ERROR', getError(error));
            });
    },
    addRecord({commit}, payload) {
        commit("SET_ERROR", null);
        commit('SET_ADD_RECORD_STATUS', 1);
        commit('SET_RECORD', null);
        InstanceService.addRecord(payload)
            .then((response) => {
                commit('SET_ADD_RECORD_STATUS', 2);
                commit('SET_RECORD', response.data.data);
            })
            .catch((error) => {
                commit('SET_ADD_RECORD_STATUS', 3);
                commit('SET_ERROR', getError(error));
            });
    },
    deleteRecord({commit}, id) {
        commit("SET_ERROR", null);
        commit('SET_DELETE_RECORD_STATUS', 1);
        InstanceService.deleteRecord(id)
            .then(() => {
                commit('SET_DELETE_RECORD_STATUS', 2);
            })
            .catch((error) => {
                commit('SET_DELETE_RECORD_STATUS', 3);
                commit('SET_ERROR', getError(error));
            });
    },

    paginateRecords({commit}, link) {
        commit("SET_ERROR", null);
        commit("SET_LOADING", true);
        commit("SET_PAGINATE_RECORD_STATUS", 1);
        InstanceService.paginateRecords(link).then((response) => {
            commit("SET_PAGINATE_RECORD_STATUS", 2);
            setPaginatedRecords(commit, response);
        }).catch((error) => {
            commit("SET_PAGINATE_RECORD_STATUS", 3);
            commit("SET_LOADING", false);
            commit("SET_ERROR", getError(error));
        });
    },

    initRecords({ commit }) {
        commit("SET_RECORDS", []);
        commit("SET_RECORD", {});
        commit("SET_PAGINATE_RECORD_STATUS", 0);
        commit("SET_BROWSE_RECORD_STATUS", 0);
        commit("SET_READ_RECORD_STATUS", 0);
        commit("SET_EDIT_RECORD_STATUS", 0);
        commit("SET_DELETE_RECORD_STATUS", 0);
    },

    availablePhoneNumberCountries({ commit }, accountId) {
        commit("SET_AVAILABLE_PHONE_NUMBER_COUNTRIES", []);
        commit("SET_AVAILABLE_PHONE_NUMBER_COUNTRIES_STATUS", 0);
        commit("SET_ERROR", null);
        commit("SET_AVAILABLE_PHONE_NUMBER_COUNTRIES_STATUS", 1);
        InstanceService.listAvailablePhoneNumberCountries(accountId).then((response) => {
            commit("SET_AVAILABLE_PHONE_NUMBER_COUNTRIES_STATUS", 2);
            commit("SET_AVAILABLE_PHONE_NUMBER_COUNTRIES", response.data);
        }).catch((error) => {
            commit("SET_AVAILABLE_PHONE_NUMBER_COUNTRIES_STATUS", 3);
            commit("SET_AVAILABLE_PHONE_NUMBER_COUNTRIES", []);
            commit("SET_ERROR", getError(error));
        });
    },

    availablePhoneNumbers({ commit }, payload) {
        commit("SET_AVAILABLE_PHONE_NUMBERS", []);
        commit("SET_AVAILABLE_PHONE_NUMBER_STATUS", 0);
        commit("SET_ERROR", null);
        commit("SET_AVAILABLE_PHONE_NUMBER_STATUS", 1);
        InstanceService.listAvailablePhoneNumbers(payload).then((response) => {
            commit("SET_AVAILABLE_PHONE_NUMBER_STATUS", 2);
            commit("SET_AVAILABLE_PHONE_NUMBERS", response.data);
        }).catch((error) => {
            commit("SET_AVAILABLE_PHONE_NUMBER_STATUS", 3);
            commit("SET_AVAILABLE_PHONE_NUMBERS", []);
            commit("SET_ERROR", getError(error));
        });
    },

    purchasePhoneNumber({commit}, payload) {
        commit("SET_PURCHASED_PHONE_NUMBER", null);
        commit("SET_PURCHASE_PHONE_NUMBER_STATUS", 0);
        commit("SET_ERROR", null);
        commit("SET_PURCHASE_PHONE_NUMBER_STATUS", 1);
        InstanceService.purchasePhoneNumber(payload).then((response) => {
            commit("SET_PURCHASE_PHONE_NUMBER_STATUS", 2);
            commit("SET_PURCHASED_PHONE_NUMBER", response.data);
        }).catch((error) => {
            commit("SET_PURCHASE_PHONE_NUMBER_STATUS", 3);
            commit("SET_PURCHASED_PHONE_NUMBER", null);
            commit("SET_ERROR", getError(error));
        });
    },
    dropdownSelected({commit, getters}, instanceId){
        let records =  JSON.parse(JSON.stringify(getters['records']));
        if(instanceId !== null && instanceId !== undefined){
            if(!records.some(instance => instance.id === instanceId)){
                //get and push to.
                InstanceService.readRecord(instanceId).then((response) => {
                    records.push(response.data.data);
                });
            }
        }
        commit("SET_RECORDS", records);
    }
};