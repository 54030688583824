import ModelView from "../../views/ModelView";
// import {AUTH_HELPER} from "../../utils/helpers";

export default [
    {
        path: '/settings',
        name: 'settings',
        redirect: '/settings/',
        component: ModelView,
        children: [
            {
                path: '',
                name: 'settings',
                meta: {
                    // action: AUTH_HELPER.authActions.browse,
                    breadcrumb: [
                        {
                            label: 'Settings',
                        }
                    ]
                },
                component: () => import('../../views/User/Index')
            },
            // {
            //     path: "new",
            //     name: "newUser",
            //     meta: {
            //         action: AUTH_HELPER.authActions.add,
            //         breadcrumb: [
            //             {
            //                 parent: 'users',
            //                 label: 'new',
            //             }
            //         ]
            //     },
            //     component: () => import('../../views/Users/UserNew'),
            // },
        ]
    },
];