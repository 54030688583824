import ModelView from "../../views/ModelView";
import {AUTH_HELPER} from "../../utils/helpers";

export default [
    {
        path: '/contacts',
        name: 'contacts',
        meta: {
            requirePermission: true,
            model: AUTH_HELPER.authModels.Contact
        },
        component: ModelView,
        children: [
            {
                path: '',
                name: 'contacts',
                meta: {
                    action: AUTH_HELPER.authActions.browse,
                    breadcrumb: [
                        {
                            label: 'Contacts',
                        }
                    ]
                },
                component: () => import('../../views/Contacts/Index')
            },
            {
                path: "/Contacts/new",
                name: "newContact",
                meta: {
                    action: AUTH_HELPER.authActions.add,
                    breadcrumb: [
                        {
                            parent: 'Contacts',
                            label: 'New',
                        }
                    ]
                },
                component: () => import('../../views/Contacts/ContactNew'),
            },
            {
                path: '/Contacts/:ContactId',
                name: 'Contact',
                meta: {
                    action: AUTH_HELPER.authActions.read,
                    breadcrumb: [
                        {
                            parent: 'Contacts'
                        }
                    ]
                },
                component: () => import('../../views/Contacts/Contact')
            },
            {
                path: "/Contacts/:ContactId/edit",
                name: "editContact",
                component: () => import('../../views/Contacts/ContactEdit'),
                meta: {
                    action: AUTH_HELPER.authActions.edit,
                    breadcrumb: [
                        {
                            parent: 'Contacts',
                            label: 'Edit',
                        }
                    ]
                },
            },
        ]
    }
];