import router from "@/router";

export default function auth({ to, next, store }) {
    const loginQuery = {
        path: "/login",
        query: { redirect: to.fullPath }
    };

    const emailVerificationQuery = {
        path: "/email-verification",
        query: { redirect: to.fullPath }
    };

    if(process.env.VUE_APP_AUTHENTICATE === 'false'){
        next();
    }

    if (!store.getters["auth/authUser"]) {
        store.dispatch("auth/getAuthUser").then(() => {
            if (!store.getters["auth/authUser"]) {
                let error = store.getters["auth/responseError"];
                if (error.response.status === 403 && error.response.data.message === "Your email address is not verified.") {
                    if (router.currentRoute.name !== "email-verification") {
                        next(emailVerificationQuery);
                    }
                }
                next(loginQuery);
            } else {
                const themeColor = store.state.auth.user.theme_color || localStorage.getItem('themeColor');
                if (themeColor) {
                    applyThemeColor(themeColor);
                    next();
                } else {
                    store.dispatch("auth/getAuthUser").then(() => {
                        const themeColor = store.state.auth.user.theme_color;
                        if (themeColor) {
                            localStorage.setItem('themeColor', themeColor);
                            applyThemeColor(themeColor);
                        }
                        next();
                    }).catch((error) => {
                        console.log("Error fetching user profile data:", error);
                        next(loginQuery);
                    });
                }
            }
        });
    } else {
        next();
    }
    
}

function applyThemeColor(themeColor) {
    document.documentElement.style.setProperty('--theme-color', themeColor);
}
