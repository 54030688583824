import ModelView from "../../views/ModelView";
import {AUTH_HELPER} from "../../utils/helpers";

export default [
    {
        path: '/accounts',
        name: 'accounts',
        redirect: '/accounts/',
        meta: {
            requirePermission: true,
            model: 'Account'
        },
        component: ModelView,
        children: [
            {
                path: '',
                name: 'accounts',
                meta: {
                    action: AUTH_HELPER.authActions.browse
                },
                component: () => import('../../views/Accounts/Index')
            },
            {
                path: "new",
                name: "newAccount",
                meta: {
                    action: AUTH_HELPER.authActions.add
                },
                component: () => import('../../views/Accounts/AccountNew'),
            },
            {
                path: ':id',
                name: 'account',
                meta: {
                    action: AUTH_HELPER.authActions.read
                },
                component: () => import('../../views/Accounts/Account')
            },
            {
                path: ":id/edit",
                name: "editAccount",
                component: () => import('../../views/Accounts/AccountEdit'),
                meta: {
                    action: AUTH_HELPER.authActions.edit
                },
            },
            {
                path: ":id/delete",
                name: "deleteAccount",
                component: () => import('../../views/Accounts/AccountDelete'),
                meta: {
                    action: AUTH_HELPER.authActions.delete
                },
            },
        ]
    }
];