import ModelView from "../../views/ModelView";
import {AUTH_HELPER} from "../../utils/helpers";

export default [
    {
        path: '/feedback',
        name: 'feedback',
        redirect: '/feedback/',
        meta: {
            requirePermission: true,
            model: AUTH_HELPER.authModels.Feedback
        },
        component: ModelView,
        children: [
            {
                path: '',
                name: 'feedback',
                meta: {
                    action: AUTH_HELPER.authActions.browse,
                    breadcrumb: [
                        {
                            label: 'Feedback',
                        }
                    ]
                },
                component: () => import('../../views/Feedback/Index')
            },
            {
                path: "/Feedback/new",
                name: "newFeedback",
                meta: {
                    action: AUTH_HELPER.authActions.add,
                    breadcrumb: [
                        {
                            parent: 'Feedback',
                            label: 'New',
                        }
                    ]
                },
                component: () => import('../../views/Feedback/FeedbackNew'),
            },
            {
                path: '/Feedback/:feedbackId',
                name: 'Feedback',
                meta: {
                    action: AUTH_HELPER.authActions.read,
                    breadcrumb: [
                        {
                            parent: 'Feedback'
                        }
                    ]
                },
                component: () => import('../../views/Feedback/Feedback')
            },
            {
                path: "/Feedback/:feedbackId/edit",
                name: "editFeedback",
                component: () => import('../../views/Feedback/FeedbackEdit'),
                meta: {
                    action: AUTH_HELPER.authActions.edit,
                    breadcrumb: [
                        {
                            parent: 'Feedback',
                            label: 'Edit',
                        }
                    ]
                },
            },
        ]
    }
];