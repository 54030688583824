import guest from "../../middleware/guest";

export default [
    {
        path: '/',
        name: 'home',
        meta: {
            middleware: [ guest ]
        },
        component: () => import('../../views/Auth/Login')
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            middleware: [ guest ]
        },
        component: () => import('../../views/Auth/Login')
    }
];