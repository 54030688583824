import { getError } from "@/utils/helpers";
import UserService from "@/services/UserService";

export const namespaced = true;

function setPaginatedUsers( commit, response ) {
    commit("SET_USERS", response.data.data);
    commit("SET_META", response.data.meta);
    commit("SET_LINKS", response.data.links);
    commit("SET_LOADING", false);
}

export const state = () => ({
        users: [],
        user: null,
        meta: null,
        links: null,
        loading: false,
        error: null,
        availableUserRoles: [],
        assignedUserRoles: [],

        saveUserStatus: 0,
        editUserStatus: 0,
        deleteUserStatus: 0,

        browseUserStatus: 0,
        readUserStatus: 0,
        paginateUserStatus: 0,
        passwordResetEmailStatus: 0,
        resendEmailVerificationStatus: 0,
        availableUserRolesStatus: 0,
        assignedUserRolesStatus: 0,
        updateUserRolesStatus: 0
    });

export const mutations = {
    SET_USERS(state, users) {
        state.users = users;
    },

    SET_USER(state, user) {
        state.user = user;
    },

    SET_META(state, meta) {
        state.meta = meta;
    },

    SET_LINKS(state, links) {
        state.links = links;
    },

    SET_LOADING(state, loading) {
        state.loading = loading;
    },

    SET_ERROR(state, error) {
        state.error = error;
    },

    SET_AVAILABLE_USER_ROLES(state, roles){
        state.availableUserRoles = roles;
    },

    SET_ASSIGNED_USER_ROLES(state, roles){
        state.assignedUserRoles = roles;
    },

    SET_SAVE_USER_STATUS(state, status) {
        state.saveUserStatus = status;
    },

    SET_EDIT_USER_STATUS(state, status) {
        state.editUserStatus = status;
    },

    SET_DELETE_USER_STATUS(state, status) {
        state.deleteUserStatus = status;
    },

    SET_BROWSE_USER_STATUS(state, status) {
        state.browseUserStatus = status;
    },

    SET_READ_USER_STATUS(state, status) {
        state.readUserStatus = status;
    },

    SET_PAGINATE_USER_STATUS(state, status) {
        state.paginateUserStatus = status;
    },

    SET_PASSWORD_RESET_EMAIL_STATUS(state, status){
        state.passwordResetEmailStatus = status;
    },

    SET_RESEND_EMAIL_VERIFICATION_STATUS(state, status){
        state.resendEmailVerificationStatus = status;
    },

    SET_AVAILABLE_USER_ROLES_STATUS(state, status){
        state.availableUserRolesStatus = status;
    },

    SET_ASSIGNED_USER_ROLES_STATUS(state, status){
        state.assignedUserRolesStatus = status;
    },

    SET_UPDATE_USER_ROLES_STATUS(state, status){
        state.updateUserRolesStatus = status;
    }
};

export const actions = {
    // ...Bread.actions,
    getUsers({ commit }, payload) {
        commit("SET_ERROR", null);
        commit("SET_LOADING", true);
        commit("SET_BROWSE_USER_STATUS", 0);
        UserService.getUsers(payload).then((response) => {
            setPaginatedUsers(commit, response);
            commit("SET_BROWSE_USER_STATUS", 2);
        }).catch((error) => {
            commit("SET_LOADING", false);
            commit("SET_BROWSE_USER_STATUS", 3);
            commit("SET_ERROR", getError(error));
        });
    },

    getUser({ commit }, userId) {
        commit("SET_ERROR", null);
        commit("SET_READ_USER_STATUS", 0);
        UserService.getUser(userId).then((response) => {
            commit("SET_READ_USER_STATUS", 2);
            commit("SET_USER", response.data.data);
        }).catch((error) => {
            commit("SET_READ_USER_STATUS", 3);
            commit("SET_USER", null);
            commit("SET_ERROR", getError(error));
        });
    },

    paginateUsers({ commit }, link) {
        commit("SET_ERROR", null);
        commit("SET_LOADING", true);
        UserService.paginateUsers(link).then((response) => {
            setPaginatedUsers(commit,response);
        }).catch((error) => {
            commit("SET_LOADING", false);
            commit("SET_ERROR", getError(error));
        });
    },

    saveUser({commit}, payload) {
        commit("SET_ERROR", null);
        commit('SET_SAVE_USER_STATUS', 1);
        UserService.saveUser(payload)
            .then((response) => {
                commit('SET_SAVE_USER_STATUS', 2);
                commit("SET_USER", response.data.data);
            })
            .catch((error) => {
                commit('SET_SAVE_USER_STATUS', 3);
                commit('SET_ERROR', getError(error));
            });
    },

    editUser({commit}, payload) {
        commit("SET_ERROR", null);
        commit('SET_EDIT_USER_STATUS', 1);
        UserService.editUser(payload)
            .then((response) => {
                commit('SET_EDIT_USER_STATUS', 2);
                commit("SET_USER", response.data.data);
            })
            .catch((error) => {
                commit('SET_EDIT_USER_STATUS', 3);
                commit('SET_ERROR', getError(error));
            });
    },

    deleteUser({commit}, id) {
        commit("SET_ERROR", null);
        commit('SET_DELETE_USER_STATUS', 1);
        UserService.deleteUser(id)
            .then(() => {
                commit('SET_DELETE_USER_STATUS', 2);
            })
            .catch((error) => {
                commit('SET_DELETE_USER_STATUS', 3);
                commit('SET_ERROR', getError(error));
            });
    },

    passwordResetEmail({commit}, id){
        commit("SET_ERROR", null);
        commit('SET_PASSWORD_RESET_EMAIL_STATUS', 1);
        UserService.passwordResetEmail(id)
            .then(() => {
                commit('SET_PASSWORD_RESET_EMAIL_STATUS', 2);
            })
            .catch((error) => {
                commit('SET_PASSWORD_RESET_EMAIL_STATUS', 3);
                commit('SET_ERROR', error.response.data.message ?? error.message);
            });
    },

    emailVerification({commit}, id){
        commit("SET_ERROR", null);
        commit('SET_RESEND_EMAIL_VERIFICATION_STATUS', 1);
        UserService.emailVerification(id)
            .then(() => {
                commit('SET_RESEND_EMAIL_VERIFICATION_STATUS', 2);
            })
            .catch((error) => {
                commit('SET_RESEND_EMAIL_VERIFICATION_STATUS', 3);
                commit('SET_ERROR', error.response.data.message ?? error.message);
            });
    },

    fetchAvailableUserRoles({commit}){
        commit("SET_AVAILABLE_USER_ROLES", []);
        commit('SET_AVAILABLE_USER_ROLES_STATUS', 1);
        UserService.getAvailableUserRoles()
            .then((response) => {
                commit("SET_AVAILABLE_USER_ROLES", response.data);
                commit('SET_AVAILABLE_USER_ROLES_STATUS', 2);
            })
            .catch((error) => {
                commit('SET_ERROR', error.response.data.message ?? error.message);
                commit("SET_AVAILABLE_USER_ROLES", []);
                commit('SET_AVAILABLE_USER_ROLES_STATUS', 3);
            });
    },

    fetchAssignedUserRoles({commit}, id){
        commit("SET_ASSIGNED_USER_ROLES", []);
        commit('SET_ASSIGNED_USER_ROLES_STATUS', 1);
        UserService.getAssignedUserRoles(id)
            .then((response) => {
                commit("SET_ASSIGNED_USER_ROLES", response.data);
                commit('SET_ASSIGNED_USER_ROLES_STATUS', 2);
            })
            .catch((error) => {
                commit("SET_ASSIGNED_USER_ROLES", []);
                commit('SET_ERROR', error.response.data.message ?? error.message);
                commit('SET_ASSIGNED_USER_ROLES_STATUS', 3);
            });
    },

    updateUserRoles({commit}, data){
        commit('SET_UPDATE_USER_ROLES_STATUS', 1);
        UserService.updateUserRoles(data)
            .then(() => {
                commit('SET_UPDATE_USER_ROLES_STATUS', 2);
            })
            .catch((error) => {
                commit('SET_ERROR', error.response.data.message ?? error.message);
                commit('SET_UPDATE_USER_ROLES_STATUS', 3);
            });
    }
};

export const getters = {
    users: (state) => {
        return state.users;
    },

    user: (state) => {
        return state.user;
    },

    meta: (state) => {
        return state.meta;
    },

    links: (state) => {
        return state.links;
    },

    loading: (state) => {
        return state.loading;
    },

    error: (state) => {
        return state.error;
    },

    availableUserRoles: (state) => {
        return state.availableUserRoles;
    },

    assignedUserRoles: (state) => {
        return state.assignedUserRoles;
    },

    saveUserStatus: (state) => {
        return state.saveUserStatus;
    },

    editUserStatus: (state) => {
        return state.editUserStatus;
    },

    deleteUserStatus: (state) => {
        return state.deleteUserStatus;
    },

    browseUserStatus: (state) => {
        return state.browseUserStatus;
    },

    readUserStatus: (state) => {
        return state.readUserStatus;
    },

    paginateUserStatus: (state) => {
        return state.paginateUserStatus;
    },

    passwordResetEmailStatus: (state) => {
        return state.passwordResetEmailStatus;
    },

    resendEmailVerificationStatus: (state) => {
        return state.resendEmailVerificationStatus;
    },

    availableUserRolesStatus: (state) => { 
        return state.availableUserRolesStatus;
    },

    assignedUserRolesStatus: (state) => { 
        return state.assignedUserRolesStatus;
    },

    updateUserRolesStatus: (state) => { 
        return state.updateUserRolesStatus;
    },
};