import guest from "../../middleware/guest";
import ModelView from "@/views/ModelView";

export default [
    // {
    //     path: "/vendors/contacts/instructions",
    //     name: "Instructions",
    //     meta: {
    //         middleware: [ guest ]
    //     },
    //     component: () => import('../../views/Vendors/Contacts/Instructions'),
    // },
    // {
    //     path: '/vendors',
    //     name: 'Vendors',
    //     redirect: '/vendors/contacts/instructions',
    //     children: [
    //         {
    //             path: '',
    //             meta: {
    //                 breadcrumb: [
    //                     {
    //                         label: 'Vendors',
    //                     }
    //                 ]
    //             },
    //             // component: () => import('../../views/Vendors/Index')
    //             component: () => import('../../views/Errors/NotFound')
    //         },
    //         {
    //             path: '/vendors/contacts',
    //             name: 'Contacts',
    //             redirect: '/vendors/contacts/instructions',
    //             children: [
    //                 {
    //                     path: '',
    //                     meta: {
    //                         breadcrumb: [
    //                             {
    //                                 parent: 'Vendors',
    //                                 label: 'Contacts',
    //                             }
    //                         ]
    //                     },
    //                     // component: () => import('../../views/Vendors/Contacts/Index')
    //                     component: () => import('../../views/Errors/NotFound')
    //                 },
    //                 {
    //                     path: "/vendors/contacts/instructions",
    //                     name: "Instructions",
    //                     meta: {
    //                         middleware: [ guest ]
    //                     },
    //                     // component: () => import('../../views/Vendors/Contacts/Instructions'),
    //                     component: () => import('../../views/Errors/NotFound')
    //                 }
    //             ]
    //         },
    //     ]
    // },
    {
        path: '/vendors',
        name: 'Vendors',
        redirect: '/vendors/contacts/instructions',
        component: ModelView,
        children: [
            {
                path: '/vendors/contacts',
                name: 'Contacts',
                redirect: '/vendors/contacts/instructions',
                component: ModelView,
                children: [
                    {
                        path: "/vendors/contacts/instructions",
                        name: "Instructions",
                        meta: {
                            middleware: [ guest ]
                        },
                        component: () => import('../../views/Vendors/Contacts/Instructions'),
                        // component: () => import('../../views/Errors/NotFound')
                    }
                ]
            }
        ]
    }
];