<template>
 <div id="AppContainer" class="overflow-hidden">
   <router-view/>
 </div>
</template>

<script>
export default {
name: "AppContainer"
}
</script>

<style scoped>

</style>