import ModelView from "../../views/ModelView";
import {AUTH_HELPER} from "../../utils/helpers";

export default [
    {
        path: '/agents',
        name: 'agents',
        redirect: '/agents/',
        meta: {
            requirePermission: true,
            model: AUTH_HELPER.authModels.Agent
        },
        component: ModelView,
        children: [
            {
                path: '',
                name: 'agents',
                meta: {
                    action: AUTH_HELPER.authActions.browse,
                    breadcrumb: [
                        {
                            label: 'Agents',
                        }
                    ]
                },
                component: () => import('../../views/Agents/Index')
            },
            {
                path: "/agents/new",
                name: "newAgent",
                meta: {
                    action: AUTH_HELPER.authActions.add,
                    breadcrumb: [
                        {
                            parent: 'Agents',
                            label: 'New',
                        }
                    ]
                },
                component: () => import('../../views/Agents/AgentNew'),
            },
            {
                path: '/agents/:agentId',
                name: 'agent',
                meta: {
                    action: AUTH_HELPER.authActions.read,
                    breadcrumb: [
                        {
                            parent: 'Agents'
                        }
                    ]
                },
                component: () => import('../../views/Agents/Agent')
            },
            {
                path: "/agents/:agentId/edit",
                name: "editAgent",
                component: () => import('../../views/Agents/AgentEdit'),
                meta: {
                    action: AUTH_HELPER.authActions.edit,
                    breadcrumb: [
                        {
                            parent: 'Agents',
                            label: 'Edit',
                        }
                    ]
                },
            },
        ]
    }
];