import ModelView from "../../views/ModelView";
import {AUTH_HELPER} from "../../utils/helpers";

export default [
    {
        path: '/bots',
        name: 'bots',
        redirect: '/bots/',
        meta: {
            requirePermission: true,
            model: AUTH_HELPER.authModels.Bot
        },
        component: ModelView,
        children: [
            {
                path: '',
                name: 'bots',
                meta: {
                    action: AUTH_HELPER.authActions.browse,
                    breadcrumb: [
                        {
                            label: 'Bots',
                        }
                    ]
                },
                component: () => import('../../views/Bots/Index')
            },
            {
                path: "/bots/new",
                name: "newBot",
                meta: {
                    action: AUTH_HELPER.authActions.add,
                    breadcrumb: [
                        {
                            parent: 'Bots',
                            label: 'New',
                        }
                    ]
                },
                component: () => import('../../views/Bots/BotNew'),
            },
            {
                path: '/bots/:botId',
                name: 'bot',
                meta: {
                    action: AUTH_HELPER.authActions.read,
                    breadcrumb: [
                        {
                            parent: 'Bots'
                        }
                    ]
                },
                component: () => import('../../views/Bots/Bot')
            },
            {
                path: "/bots/:botId/edit",
                name: "editBot",
                component: () => import('../../views/Bots/BotEdit'),
                meta: {
                    action: AUTH_HELPER.authActions.edit,
                    breadcrumb: [
                        {
                            parent: 'Bots',
                            label: 'Edit',
                        }
                    ]
                },
            },
        ]
    }
];