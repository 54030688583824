import * as API from "@/services/API";

const baseUrl = '/v1/reporting';


export default {
    getConversations(filters) {
        return API.apiClient.get(`${baseUrl}/conversations`, {params: filters});
    },
    getRoles(filters) {
        return API.apiClient.get(`${baseUrl}/roles`, {params: filters});
    },
    getRoleBreakdown(filters) {
        return API.apiClient.get(`${baseUrl}/roles/${filters.role_id}`, {params: filters});
    },
    getInstances(filters) {
        return API.apiClient.get(`${baseUrl}/instances`, {params: filters});
    },
    getInstanceBreakdown(filters) {
        return API.apiClient.get(`${baseUrl}/instances/${filters.instance_id}`, {params: filters});
    },
    getGlobalStatistics(filters) {
        return API.apiClient.get(`${baseUrl}/global`, {params: filters});
    },
    getGlobalActiveStatistics(filters) {
        return API.apiClient.get(`${baseUrl}/global/active`, {params: filters});
    },
    getGlobalTimeSeries(filters) {
        return API.apiClient.get(`${baseUrl}/global/timeseries`, {params: filters});
    },
    getGlobalRoles(filters) {
        return API.apiClient.get(`${baseUrl}/global/roles`, {params: filters});
    },
    getGlobalRoleRankings(filters) {
        return API.apiClient.get(`${baseUrl}/global/roles/rankings`, {params: filters});
    },
}