import ModelView from "../../views/ModelView";
import {AUTH_HELPER} from "../../utils/helpers";

export default [
    {
        path: '/chat',
        name: 'chat',
        redirect: '/chat/',
        meta: {
            requirePermission: true,
            model: AUTH_HELPER.authModels.Chat
        },
        component: ModelView,
        children: [
            {
                path: '',
                name: 'chat',
                meta: {
                    action: AUTH_HELPER.authActions.browse,
                    breadcrumb: [
                        {
                            label: 'Chat',
                        }
                    ]
                },
                component: () => import('../../views/Chat/Index')
            },
        ]
    }
];